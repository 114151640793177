//
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: $auth-img-bg;
    background-size: cover;
    background-position: center;
}

// Logout page
.logout-icon {
    width: 140px;
}

.auth-logo {
    .logo-light {
        display: $logo-auth-light-display !important;
    }
    .logo-dark {
        display: $logo-auth-dark-display !important;
    }
}

// dark mode
body[data-layout-color='dark'] {
    .auth-logo {
        .logo-light {
            display: $logo-auth-dark-display !important;
        }
        .logo-dark {
            display: $logo-auth-light-display !important;
        }
    }
    &.authentication-bg {
        background-image: $auth-img-bg-dark;
    }
}

// transparent form

.auth-transparent-form {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
